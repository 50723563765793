// import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Slider from 'react-slick';
// import scrollToPage from '../../../../utils/ScrollToLink';
import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import AccommodationList from './AccommodationList';
import variables from '../../../../style/variables.scss';

const Accommodation = () => {
  // const location = useLocation();
  // const navigate = useNavigate();

  const [sliderTexts, setSliderTexts] = useState<Slider>();
  const [sliderImgs, setSliderImgs] = useState<Slider>();

  const slickSettingsTitles = {
    initialSlide: 5,
    centerMode: true,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    infinite: true,
    focusOnSelect: true,
    slidesToShow: 3,
    arrows: true,
    speed: 500,
    slidesToScroll: 1,
    asNavFor: sliderImgs,
    prevArrow: (
      <div className='opacity_transition_low'>
        {Constants.arrowLeft(variables.secondaryColor)}
      </div>
    ),
    nextArrow: (
      <div className='opacity_transition_low'>
        {Constants.arrowRight(variables.secondaryColor)}
      </div>
    ),
  };

  const slickSettingsImgs = {
    initialSlide: 5,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    arrows: false,
    speed: 500,
    slidesToScroll: 1,
    asNavFor: sliderTexts,
  };

  return (
    <div id={Constants.ACCOMMODATION} className='accommodation'>
      <div className='accommodation_container container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
            <h2 className='text_center'>ACCOMMODATION</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={150}>
            <h3 className='title_underline_light_small'>
              SMILE ALL THE STAY, THIS IS YOUR TIME
            </h3>
            <p>
              All rooms and suites include a flat-screen TV with satellite
              channels, air-conditioning, a seating area, mini bar and city
              views. The bathrooms are fitted with walk-in showers.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn' delay={200}>
            <Slider
              {...slickSettingsTitles}
              ref={(slider) =>
                slider ? setSliderTexts(slider) : setSliderTexts(undefined)
              }
              className='accommodation_container_slick_title'
            >
              {AccommodationList.map((x) => (
                <div className='accommodation_container_slick_title_container'>
                  {x.accommodationtTitle}
                </div>
              ))}
            </Slider>
            <Slider
              {...slickSettingsImgs}
              ref={(slider) =>
                slider ? setSliderImgs(slider) : setSliderImgs(undefined)
              }
              className='accommodation_container_slick'
            >
              {AccommodationList.map((x) => (
                <div className='accommodation_container_slick_container'>
                  <img
                    src={x.accommodationtImage}
                    alt={x.accommodationtTitle}
                    className='accommodation_container_slick_container_image'
                  />
                  <div
                    data-index={`${AccommodationList.indexOf(x)}`}
                    className='accommodation_container_slick_container_box'
                  >
                    <div className='accommodation_container_slick_container_box_title title_underline_complementary_small'>
                      {x.accommodationtTitle}
                    </div>
                    <div className='accommodation_container_slick_container_box_links'>
                      <a
                        href={Constants.bookNowLink}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <div>BOOK NOW</div>
                      </a>
                      {/* <div
                        onClick={() =>
                          scrollToPage(x.accommodationtLink, location, navigate)
                        }
                      >
                        SHOW MORE
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </ScrollAnimation>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Accommodation;
